import React, { useState } from 'react'
import { useRef } from 'react'
import LearningApis from '../../../queries/learning'
import profilePic from '../../../assets/images/profilepic.png'
import { useOutletContext } from 'react-router-dom';

function CourseOverview({ learningData, learningNotes }) {
    const { userDetails } = useOutletContext();
    const [qstnId, setqstnId] = useState()
    const { data: QandAdata, refetch: questionsRefetch } = LearningApis.GetQandA(learningData?.data?.videos?.id)
    const { data: answersList, refetch: answersRefetch } = LearningApis.GetQuestionAnswers(qstnId, learningData?.data?.videos?.id)
    const postComments = LearningApis.Postcomments()
    const makeLike = LearningApis.MakeLikes()
    const dltComment = LearningApis.DltComment()
    const replyRef = useRef("")
    const [replyBoxStatus, setreplyBoxStatus] = useState(false)
    const [replyQstnId, setreplyQstnId] = useState()
    const [clickedQuestions, setClickedQuestions] = useState([])
    const [likedQuestions, setlikedQuestions] = useState([])
    const [commentBoxValue, setcommentBoxValue] = useState('')
    const [setLikeCount, setsetLikeCount] = useState()
    function shortenString(str, maxLength) {
        return str.length > maxLength ? str.slice(0, maxLength - 3) + "..." : str;
    }
    let question = { id: 1, question: "Lorem description lorem text of dummy text for web Lorem description lorem text of dummy text for web" }
    const replayHandler = (qstn, index) => {
        const replyQstn = index + 1 + '.' + " " + shortenString(qstn?.comment, 50)
        replyRef.current = replyQstn
        setreplyBoxStatus(true)
        setreplyQstnId(qstn.id)
    }
    const [isTextareaFocused, setIsTextareaFocused] = useState(false);
    const handleFocus = () => setIsTextareaFocused(true);
    const handleBlur = () => setIsTextareaFocused(false);


    const showMoreAnswers = (qsnId) => {
        if (clickedQuestions.includes(qsnId)) {
            setClickedQuestions([])
        } else {
            setClickedQuestions([qsnId]);
        }

        setqstnId(qsnId);
    };

    const commentSendHandler = async () => {
        const payload = {
            taskId: learningData?.data?.videos?.id,
            comment: commentBoxValue
        }
        if (replyBoxStatus) {
            payload.replyTo = replyQstnId
        }
        const response = await postComments.mutateAsync(payload)
        console.log("==response=====", response)
        if (response.status) {
            await questionsRefetch()
            setcommentBoxValue('')
            await questionsRefetch()
            await answersRefetch()
        }
    }

    const commentBoxchangeHandler = (e) => {
        setcommentBoxValue(e.target.value)
    }
    const likeHadnler = async (question, type) => {
        console.log("==qstnId===", question.id)

        let payload = {
            commentId: question.id
        }
        if (likedQuestions.includes(question.id)) {
            setlikedQuestions(likedQuestions.filter((id) => id !== question.id));
            payload.action = 0


        } else {
            payload.action = 1
            setlikedQuestions([...likedQuestions, question.id])
        }
        const response = await makeLike.mutateAsync(payload)
        if (response.status) {
            if (type === "answerLike") {
                await answersRefetch()
            } else {
                await questionsRefetch()
            }

        }
    }
    const commentDlthandle = async (id, type) => {
        const response = await dltComment.mutateAsync({ commentId: id })
        if (response.status) {
            if (type === "qstnDlt") {
                await questionsRefetch()
            } else {
                await answersRefetch()
            }
        }
    }

    return (
        <div className="col-md-8">
            <nav>
                <div className="nav nav-tabs mb-3" id="nav-tab" role="tablist">
                    <button
                        className="nav-link active"
                        id="nav-tab1-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-tab1"
                        type="button"
                        role="tab"
                        aria-controls="nav-tab1"
                        aria-selected="true"
                    >
                        Overview
                    </button>
                    <button
                        className="nav-link"
                        id="nav-tab2-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-tab2"
                        type="button"
                        role="tab"
                        aria-controls="nav-tab2"
                        aria-selected="false"
                    >
                        Comment
                    </button>
                    {/* <button
                        className="nav-link"
                        id="nav-tab3-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-tab3"
                        type="button"
                        role="tab"
                        aria-controls="nav-tab3"
                        aria-selected="false"
                    >
                        Notes
                    </button> */}
                </div>
            </nav>
            <div className="tab-content" id="nav-tabContent">
                <div
                    className="tab-pane fade active show"
                    id="nav-tab1"
                    role="tabpanel"
                    aria-labelledby="nav-tab1-tab"
                >
                    <div className="row">
                        <div className="col-md-12">
                            <h4>What you’ll learn</h4>
                            <p style={{wordWrap:'anywhere'}}>
                                {learningData?.data?.videos?.overview}
                            </p>
                        </div>
                    </div>
                    {/* <div className="row mt-4">
                        <div className="col-md-12">
                            <h4>This course includes:</h4>
                            <div className="row">
                                <div className="col-md-6">
                                    <ul>
                                        <li>Dunt ut labore et dolore ma gna aliquaim.</li>
                                        <li>Dunt ut labore et dolore ma gna.</li>
                                        <li>Dunt ut labore et dolore ma gna aliquaim lorem.</li>
                                    </ul>
                                </div>
                                <div className="col-md-6">
                                    <ul>
                                        <li>Dunt ut labore et dolore ma gna aliquaim.</li>
                                        <li>Dunt ut labore et dolore ma gna.</li>
                                        <li>Dunt ut labore et dolore ma gna aliquaim lorem.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
                <div className="tab-pane fade" id="nav-tab2" role="tabpanel" aria-labelledby="nav-tab2-tab" >
                    <div style={{ maxHeight: '500px', overflow: 'auto' }} className='p-3'>
                        {QandAdata?.data &&
                            QandAdata?.data.map((item, index) => (
                                <div className="reviewViewRow" key={item.id}>
                                    <div className="commentImg"><img src={item.profilePhoto ?? profilePic} /></div>
                                    <div className="reviewViewCnt">
                                        <div className="question-container">
                                            <h6>{index + 1}. {item?.comment}</h6>
                                            <div className="reply-btn">
                                                <span onClick={() => replayHandler(item, index)} style={{ cursor: 'pointer' }}>
                                                    <i className="fa-solid fa-reply fa-lg"></i>
                                                </span>


                                                <div onClick={() => likeHadnler(item, 'questionLike')}>
                                                    {
                                                        item?.userLiked === 1 ?
                                                            <i class="fa-solid fa-heart fa-lg" style={{ color: '#f72d2d' }}></i>
                                                            : <i class="fa-regular fa-heart fa-lg"></i>
                                                    }

                                                </div>
                                                <span style={{ textAlign: 'center' }}>{item.likes}</span>
                                                {
                                                    item?.isDeletable &&
                                                    <span onClick={() => commentDlthandle(item.id, 'qstnDlt')}><i class="fa-solid fa-trash fa-lg"></i></span>

                                                }
                                            </div>
                                            
                                        </div>
                                        {/* <span>Answer {index + 1}</span> */}
                                        <p>{item?.reply?.comment}</p>
                                        <p><code>.created By</code>@{item.by}</p>
                                        <div className="answersList-container">
                                            <div style={{ opacity: 0 }}>ds</div>
                                            <div className="reply-btn" style={{ cursor: 'pointer' }}>
                                                <span onClick={() => showMoreAnswers(item.id)}>
                                                    {clickedQuestions.includes(item.id) ? (
                                                        <i className="fa-solid fa-angle-up fa-xl"></i>
                                                    ) : (
                                                        <i class="fa-solid fa-angle-down fa-xl"></i>
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                        {qstnId === item.id && clickedQuestions.includes(item.id) && (
                                            <div>
                                                <React.Fragment>
                                                    <div>
                                                        <React.Fragment>
                                                            {
                                                                answersList?.data?.length > 0 ?
                                                                    <ul className="active">
                                                                        {answersList?.data.map((item, index) => (
                                                                            <>
                                                                                <li key={index}>{item.comment} <br />
                                                                                    <small> <code>.created By</code>@ {item.by} </small>
                                                                                    {
                                                                                        item.userLiked === 1 ?
                                                                                            <><i class="fa-solid fa-heart " style={{ color: '#f72d2d', cursor: 'pointer', marginLeft: '2px' }} onClick={() => likeHadnler(item, 'answerLike')}> </i> <small>{item.likes > 0 ? item.likes : ''}</small></>
                                                                                            : <><i class="fa-regular fa-heart" style={{ cursor: 'pointer', marginLeft: '2px' }} onClick={() => likeHadnler(item, 'answerLike')}> </i> <small>{item.likes > 0 ? item.likes : ''}</small></>
                                                                                    }
                                                                                    {" "}
                                                                                    {
                                                                                        item?.isDeletable&&
                                                                                        <i class="fa-solid fa-trash fa-sm" style={{ color: '#6d6565', cursor: 'pointer' }} onClick={() => commentDlthandle(item.id, "answerDlt")}></i>
                                                                                    }
                                                                                   





                                                                                </li>

                                                                            </>

                                                                        ))}
                                                                    </ul>
                                                                    :
                                                                    <p>No answers found :(</p>

                                                            }

                                                        </React.Fragment>
                                                    </div>
                                                </React.Fragment>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ))}

                    </div>



                    {replyBoxStatus && (
                        <div className={`reply-section ${isTextareaFocused ? "active" : ""}`} >
                            <p>{replyRef?.current}</p>
                            <span onClick={() => setreplyBoxStatus(false)}>
                                <i className="fa-solid fa-xmark fa-sm"></i>
                            </span>
                        </div>
                    )}

                    <textarea
                        className="form-control reply-textarea"
                        id="exampleFormControlTextarea1"
                        rows="3"
                        style={{ borderRadius: 0 }}
                        placeholder="Type your question or answer..."
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        onChange={commentBoxchangeHandler}
                        value={commentBoxValue}
                    ></textarea>


                    <button className='mt-3 comment-send-btn' onClick={commentSendHandler} disabled={postComments?.isPending}>send</button>

                </div>
                {/* <div
                    className="tab-pane fade"
                    id="nav-tab3"
                    role="tabpanel"
                    aria-labelledby="nav-tab3-tab"
                >
                    <h5>Notes</h5>
                    <div className='row'>
                        <div className="col-md-6">
                            <ul>
                                {
                                    learningNotes?.data &&
                                    learningNotes?.data?.map((item, index) => (
                                        <li>{item.note}</li>
                                    ))
                                }
                            </ul>
                        </div>
                    </div>
                   
                </div> */}
            </div>
        </div>

    )
}

export default CourseOverview