import React, { useEffect } from 'react'
import PersonalDetails from './PersonalDetails'
import AccountDetails from './AccountDetails'
import KycDetails from './KycDetails'
import LanguageSettings from './LanguageSettings'
import Badges from './Badges'
import Notifications from './Notifications'

function EditProfile({ api, profileData }) {

    return (
        <div className="edit_profile_view_sec">
            <h4 className="mt-3">Edit Profile</h4>
            <div className="row mt-3">
                <div className="col-md-3">
                    <nav>
                        <div className="nav nav-tabs mb-3" id="nav-tab" role="tablist">
                            <button className="nav-link active" id="nav-tab1-tab" data-bs-toggle="tab" data-bs-target="#nav-tab1" type="button" role="tab" aria-controls="nav-tab1" aria-selected="true">
                                Personal Information
                            </button>
                            {/* <button className="nav-link" id="nav-tab2-tab" data-bs-toggle="tab" data-bs-target="#nav-tab2" type="button" role="tab" aria-controls="nav-tab2" aria-selected="false">
                                Account Details
                            </button> */}
                            {/* <button className="nav-link" id="nav-tab3-tab" data-bs-toggle="tab" data-bs-target="#nav-tab3" type="button" role="tab" aria-controls="nav-tab3" aria-selected="false">
                                KYC
                            </button> */}
                            {/* <button className="nav-link" id="nav-tab4-tab" data-bs-toggle="tab" data-bs-target="#nav-tab4" type="button" role="tab" aria-controls="nav-tab4" aria-selected="false">
                                Language Settings
                            </button> */}
                            {/* <button className="nav-link" id="nav-tab5-tab" data-bs-toggle="tab" data-bs-target="#nav-tab5" type="button" role="tab" aria-controls="nav-tab5" aria-selected="false">
                                Badges
                            </button> */}
                            {/* <button className="nav-link" id="nav-tab6-tab" data-bs-toggle="tab" data-bs-target="#nav-tab6" type="button" role="tab" aria-controls="nav-tab6" aria-selected="false">
                                Notification
                            </button> */}
                            {/* <button className="nav-link" id="nav-tab7-tab" data-bs-toggle="tab" data-bs-target="#nav-tab7" type="button" role="tab" aria-controls="nav-tab7" aria-selected="false">
                                Course Details
                            </button> */}
                        </div>
                    </nav>
                </div>
                <div className="col-md-9">
                    <div className="tab-content" id="nav-tabContent">
                        <div className="tab-pane fade active show" id="nav-tab1" role="tabpanel" aria-labelledby="nav-tab1-tab">
                            {
                                profileData && <PersonalDetails api={api} profileData={profileData} />
                            }

                        </div>
                        <div className="tab-pane fade" id="nav-tab2" role="tabpanel" aria-labelledby="nav-tab2-tab">
                            {profileData && <AccountDetails profileData={profileData} />}
                        </div>
                        <div className="tab-pane fade" id="nav-tab3" role="tabpanel" aria-labelledby="nav-tab3-tab">
                            <KycDetails />
                        </div>
                        <div className="tab-pane fade" id="nav-tab4" role="tabpanel" aria-labelledby="nav-tab4-tab">
                            {profileData && <LanguageSettings api={api} profileData={profileData} />}
                        </div>
                        <div className="tab-pane fade" id="nav-tab5" role="tabpanel" aria-labelledby="nav-tab5-tab">
                            {profileData && <Badges api={api} profileData={profileData} />}
                        </div>
                        {/* <div className="tab-pane fade" id="nav-tab6" role="tabpanel" aria-labelledby="nav-tab6-tab">
                            <Notifications />
                        </div> */}
                    </div>
                </div>
            </div>
        </div>

    )
}

export default EditProfile