import React from 'react'
import courseImg from '../../assets/images/course2.jpg'
import { Link, useParams, useNavigate } from 'react-router-dom'
import CourseApis from '../../queries/course'
import Overview from '../../components/Course/CourseDetails/Overview'
import Modules from '../../components/Course/CourseDetails/Modules'
import Reviews from '../../components/Course/CourseDetails/Reviews'
import CourseRequirement from '../../components/Course/CourseDetails/CourseRequirement'
import toast from 'react-hot-toast'
import { useAuth } from '../../context/AuthContext'
import { useTranslation } from 'react-i18next'
function CourseDetails() {
    const { id } = useParams()
    const { data: courseDetails } = CourseApis.ViewCourseDetails(id)
    const addTocart = CourseApis.AddtoCart()
    const {t}=useTranslation('purchase')
    const navigate = useNavigate()
    const { isAuthenticated } = useAuth()
    const addTocartHandler = async () => {
        if (isAuthenticated) {
            const response = await addTocart.mutateAsync({ courseId: id })
            if (response.status) {
                navigate('/cart')
            } else {
                toast.error(t(response?.error?.message))
            }
        } else {
            navigate('/login')
        }

    }
    return (
        <>
            <div className="course_banner_sec">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-8">
                            <span>Home - Category - Course Details</span>
                            <h4>{courseDetails?.data?.course?.title}</h4>
                            <p>{courseDetails?.data?.course?.description ?? 'Lorem description lorem text of dummy text for web ...'}</p>
                            <span className="star_rating">
                                {[...Array(5)].map((_, index) => (
                                    <i
                                        key={index}
                                        className={`fa-solid fa-star ${index < Math.ceil(courseDetails?.data?.rating?.averageRating) ? 'active' : ''}`}
                                    ></i>
                                ))}

                                {courseDetails?.data?.rating?.total}
                            </span>
                            {/* <span>Tutors -&gt; {courseDetails?.data?.course?.tutor?.username}, Name</span> */}
                            <Link to="" className="btn_add_card" onClick={addTocartHandler}>
                                <span>
                                    <p><span className="strikethrough">${courseDetails?.data?.course?.price}</span> {courseDetails?.data?.discount?.value}% OFF</p>
                                    <strong>${courseDetails?.data?.discount?.discountPrice}</strong>
                                </span>
                                <span className="center_line"></span>
                                <span>
                                    ADD TO CART
                                </span>
                            </Link>
                        </div>
                        <div className="col-md-4">
                            <div className="course_bann_img">
                                <img src={courseDetails?.data?.course?.image??courseImg} className="img-fluid" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="course_content_sec">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <nav>
                                <div className="nav nav-tabs mb-3" id="nav-tab" role="tablist">
                                    <button className="nav-link active" id="nav-tab1-tab" data-bs-toggle="tab" data-bs-target="#nav-tab1" type="button" role="tab" aria-controls="nav-tab1" aria-selected="true">Overview</button>
                                    <button className="nav-link" id="nav-tab2-tab" data-bs-toggle="tab" data-bs-target="#nav-tab2" type="button" role="tab" aria-controls="nav-tab2" aria-selected="false">Modules</button>
                                    <button className="nav-link" id="nav-tab3-tab" data-bs-toggle="tab" data-bs-target="#nav-tab3" type="button" role="tab" aria-controls="nav-tab3" aria-selected="false">Reviews</button>
                                    <button className="nav-link" id="nav-tab4-tab" data-bs-toggle="tab" data-bs-target="#nav-tab4" type="button" role="tab" aria-controls="nav-tab4" aria-selected="false">Course Requirements</button>
                                </div>
                            </nav>
                            <div className="tab-content" id="nav-tabContent">
                                <div className="tab-pane fade show active" id="nav-tab1" role="tabpanel" aria-labelledby="nav-tab1-tab">
                                    <Overview courseDetails={courseDetails} />
                                </div>
                                <div className="tab-pane fade" id="nav-tab2" role="tabpanel" aria-labelledby="nav-tab2-tab">
                                    <Modules courseDetails={courseDetails} />
                                </div>
                                <div className="tab-pane fade" id="nav-tab3" role="tabpanel" aria-labelledby="nav-tab3-tab">
                                    <Reviews courseDetails={courseDetails} />
                                </div>
                                <div className="tab-pane fade" id="nav-tab4" role="tabpanel" aria-labelledby="nav-tab4-tab">
                                    <CourseRequirement courseDetails={courseDetails} />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="course_dtl_level_box">
                                <div className="course_dtl_level_row">
                                    <strong>Beginner Level</strong>
                                    {/* <span>No prior experience required</span> */}
                                </div>
                                <div className="course_dtl_level_row">
                                    <strong>{courseDetails?.data?.duration?.hour?.hours} Hours and {courseDetails?.data?.duration?.hour?.minutes} Minutes to Complete</strong>
                                    <span>{courseDetails?.data?.duration?.weeksToComplete?.weeks} Week {courseDetails?.data?.duration?.weeksToComplete?.weeklyHours} hour a week</span>
                                </div>
                                <div className="course_dtl_level_row border-0">
                                    <strong>{courseDetails?.data?.counts?.modules} Sections</strong>
                                    {/* <span>1 Practice test</span> */}
                                </div>
                                <Link to='' className="btn_add_card" onClick={addTocartHandler}>
                                    <span>
                                        <p><span className="strikethrough">${courseDetails?.data?.course?.price}</span> {courseDetails?.data?.discount?.value}% OFF</p>
                                        <strong>${courseDetails?.data?.discount?.discountPrice}</strong>
                                    </span>
                                    <span className="center_line"></span>
                                    <span>
                                        ADD TO CART
                                    </span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CourseDetails