import React, { useState } from 'react'
import ProfileDetailsSchema from '../../../schema/profileDetails'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import ProfileApis from '../../../queries/profile'


function PersonalDetails({ api, profileData }) {

    const {
        register,
        handleSubmit,
        trigger,
        setValue,
        formState: { errors },
        watch,
    } = useForm({
        defaultValues: {
            firstName: profileData?.user_detail?.firstName,
            lastName: profileData?.user_detail?.lastName,
            mobile: profileData?.user_detail?.mobile,
            countryId: profileData?.user_detail?.countryId,
            stateId: profileData?.user_detail?.stateId
        },
        resolver: yupResolver(ProfileDetailsSchema),
    })
    const formValues = watch()


    //api call
    const editPersonalDetails = ProfileApis.EditPersonalDetails()
    const { data: Countries } = ProfileApis.GetCountries()
    const { data: States } = ProfileApis.GetStates(formValues?.countryId)

    const saveHandler = async (data) => {
        const isValid = await trigger()
        if (!isValid) return toast.error("check your data")
        const response = await editPersonalDetails.mutateAsync(data)
        if (response.status) {
            toast.success('personal details updated')
            await api.refetch();
        } else {
            toast.error('updation failed')
        }

    }


    const countrySelecthandler = (e) => {
        const { value } = e.target
        setValue("countryId", value)
    }

    return (
        <div className="row">
            <div className="col-md-6">
                <div className="form-floating mb-3">
                    <input type="text" {...register('firstName')} className={`form-control ${errors.firstName ? 'is-invalid' : ''}`} id="floatingInput" placeholder="name@example.com" />
                    <label htmlFor="floatingInput">First Name</label>
                    <div class="invalid-feedback">
                        {errors?.firstName?.message}
                    </div>
                </div>
            </div>
            <div className="col-md-6">
                <div className="form-floating mb-3">
                    <input type="text" {...register('lastName')} className={`form-control ${errors.lastName ? 'is-invalid' : ''}`} id="floatingInput" placeholder="name@example.com" />
                    <label htmlFor="floatingInput">Last Name</label>
                    <div class="invalid-feedback">
                        {errors?.lastName?.message}
                    </div>
                </div>
            </div>
            <div className="col-md-6">
                <div className="form-floating mb-3">
                    <input type="text" {...register('mobile')} className={`form-control ${errors.mobile ? 'is-invalid' : ''}`} id="floatingInput" placeholder="name@example.com" />
                    <label htmlFor="floatingInput">Phone</label>
                    <div class="invalid-feedback">
                        {errors?.mobile?.message}
                    </div>
                </div>
            </div>
            {/* <div className="col-md-6">
                <div className="form-floating mb-3">
                    <select className={`form-select ${errors.countryId ? 'is-invalid' : ''}`} {...register('countryId')} id="floatingSelect" aria-label="Floating label select example" onChange={countrySelecthandler}>
                        <option value="">select</option>
                        {
                            Countries?.data &&
                            Countries?.data.map((items, index) => {
                                return (
                                    <option value={items.value} selected={items.value === formValues.countryId}>{items.title}</option>
                                )
                            })
                        }


                    </select>
                    <label htmlFor="floatingInput">Country</label>
                    <div class="invalid-feedback">
                        {errors?.countryId?.message}
                    </div>
                </div>
            </div>
            <div className="col-md-6">
                <div className="form-floating mb-3">
                    <select className={`form-select ${errors.stateId ? 'is-invalid' : ''}`} {...register('stateId')} id="floatingSelect" aria-label="Floating label select example">
                        <option value="">select</option>
                        {
                            States?.data &&
                            States?.data.map((item, index) => (
                                <option value={item.value} selected={item.value === formValues.stateId}>{item.title}</option>
                            ))
                        }

                    </select>
                    <label htmlFor="floatingInput">State</label>
                    <div class="invalid-feedback">
                        {errors?.stateId?.message}
                    </div>
                </div>
            </div> */}
            <div className="row">
                <div className="col-md-5">
                    <button className="btn btn-primary" onClick={handleSubmit(saveHandler)}>SAVE</button>
                </div>
            </div>
        </div>
    )
}

export default PersonalDetails