import React, { useEffect, useRef, useState } from 'react'
import KycApis from '../../../queries/kyc'
import Select from 'react-select'
import toast from 'react-hot-toast'
import moment from 'moment/moment'
import noDataImg from '../../../assets/images/No data-cuate.png'
import TableSkeleton from '../../../utils/skeletons/TableSkeleton'
function KycDetails() {
    const getKycCategories = KycApis.GetKycCategories()
    const uploadKyc = KycApis.UploadKyc()
    const dltKycFile = KycApis.DltKycFile()
    const [isMulty, setisMulty] = useState(true)
    const [selectedCtegory, setselectedCtegory] = useState(null)
    const [category, setcategory] = useState(null)
    const getKyc = KycApis.GetUploadedKycs(category)
    console.log("==category====", category)
    const [file, setfile] = useState([])
    const [selectedCtgryFilecount, setselectedCtgryFilecount] = useState()
    const fileInputRef = useRef(null);
    const [fileDisable, setfileDisable] = useState(true)
    const [fileUploadCount, setfileUploadCount] = useState()
    const [errors, seterrors] = useState({
        file: '',
        category: ''
    })
    console.log("====getKycCategories==", getKyc?.data?.data)
    console.log("====category====", category)
    const convertOptions = getKycCategories?.data?.data.map((item) => {
        return {
            label: item.name,
            value: item.id
        }
    })
    const selectCtgryHandler = (value) => {
        setcategory(value.value)
        setselectedCtegory(value)
        setfileDisable(false)


    }
    useEffect(() => {
        setfileUploadCount(getKyc?.data?.data?.slots)
        const pendingFiles = getKyc?.data?.data
        console.log("filedf====", pendingFiles)
        if (pendingFiles) {
            if (pendingFiles.slots === 0) {
                setfileDisable(true)
            } else {

                if (pendingFiles.slots > 1) {
                    setisMulty(true)
                } else {
                    setisMulty(false)
                }
                setfileDisable(false)
            }


        }
    }, [getKyc?.data?.data])

    const fileChangeHandler = (e) => {
        // if (fileInputRef.current) {

        // }
        let file = Array.from(e.target.files)
        console.log("=====file=====", file)
        if (file.length > selectedCtgryFilecount) {
            seterrors({
                ...errors,
                file: `Max allowed files count is ${selectedCtgryFilecount}`
            })
            fileInputRef.current.value = '';
            // e.target.value = ''
            // file = []

        } else {
            seterrors({
                ...errors,
                file: ''
            })
            setfile(file)
        }
    }

    const validateForm = (file, selectedCtegory) => {
        if (file.length === 0) {
            return { isValid: false, field: 'file', message: 'Select your file' };
        }

        if (!selectedCtegory) {
            return { isValid: false, field: 'category', message: 'Select category' };
        }

        return { isValid: true };
    };

    const uploadHandler = async () => {
        const validation = validateForm(file, selectedCtegory);

        if (!validation.isValid) {
            seterrors((prevErrors) => ({
                ...prevErrors,
                [validation.field]: validation.message
            }));
            return;
        }


        seterrors({
            file: '',
            category: ''
        });


        const formData = new FormData();
        file.forEach((f) => formData.append('file', f));
        formData.append('category', category);

        try {
            const response = await uploadKyc.mutateAsync(formData);
            if (response.status) {
                toast.success('File uploaded successfully');
                setfile([]);
                setselectedCtegory(null);
                fileInputRef.current.value = ''
                await getKyc.refetch()
            } else {
                toast.error('File upload failed');
            }
        } catch (error) {
            console.error('Upload failed:', error);
            toast.error('File upload failed');
        }
    };

    console.log("==slots===", getKyc?.data?.data.slots)
    const deleteHandler = async (id) => {
        let payload = {}
        if (category) {
            payload.fileId = id
        } else {
            payload.categoryId = id
        }
        const response = await dltKycFile.mutateAsync(payload)
        if (response.status) {
            await getKyc.refetch()
        }
    }
    return (
        <>
            <div className="row">
                <div className="col-md-4">
                    <div className="mb-3">
                        <label for="formFileMultiple" className="form-label">Select | <small>Category</small></label>
                        <Select
                            options={convertOptions}
                            value={selectedCtegory}
                            onChange={selectCtgryHandler}
                            name='category'
                        />
                        {
                            errors.category &&
                            <span style={{ color: 'red' }}>{errors.category}</span>
                        }
                    </div>

                </div>

                <div className="col-md-8">
                    <div className="mb-3">
                        <label for="formFileMultiple" className="form-label">ID Proof | <small>Front and Back Image</small></label>
                        <div class="input-group" >
                            <input type="file" name='file' ref={fileInputRef} className={`form-control ${errors.file ? 'is-invalid' : ''}`} id="inputGroupFile04" aria-describedby="inputGroupFileAddon04" aria-label="Upload"
                                multiple={isMulty} disabled={fileDisable} onChange={fileChangeHandler} />

                            <button class="btn kyc-btn" type="button" id="inputGroupFileAddon04" disabled={fileDisable} onClick={uploadHandler}>Upload</button>
                            <div class="invalid-feedback">
                                {errors?.file}
                            </div>
                        </div>

                    </div>

                </div>

            </div>
            <hr />
            <div className='row'>
                <div className='col-lg-12'>
                    <table className='kyc-table' style={{ textAlign: 'center' }}>
                        <thead className='kyc-table-head'>
                            <th>No</th>
                            <th>Category</th>
                            <th>File</th>
                            <th>Uploaded Date</th>
                            <th>Status</th>
                            <th>Action</th>
                        </thead>
                     
                        {
                            getKyc.isLoading ?
                                <TableSkeleton rowCount={6} colCount={6} />
                                :
                                <tbody>
                                    {
                                        getKyc?.data?.data?.tableData ?
                                            getKyc?.data?.data?.tableData?.map((item, index) => {
                                                return (
                                                    <tr>
                                                        <td>{index + 1}</td>
                                                        <td>{item.category}</td>
                                                        <td>{item.file.map((item, index) => {
                                                            return (
                                                                <div className='kyc-image-container'>
                                                                    <img src={item} className='w-100' />
                                                                </div>

                                                            )
                                                        })}
                                                        </td>
                                                        <td>{moment(item.date).format('DD/MM/YYYY')}</td>
                                                        <td>{item.approved}</td>
                                                        <td><div className='kyc-dlt' onClick={() => deleteHandler(item.id)}><i class="fa-solid fa-trash" ></i></div></td>
                                                    </tr>
                                                )
                                            })
                                            :
                                            <tr>
                                                <td colSpan={6}>
                                                    <img src={noDataImg} className='w-50' />
                                                    <span>No data found</span>
                                                </td>
                                            </tr>
                                    }

                                </tbody>
                        }

                    </table>
                </div>
            </div>

        </>

    )
}

export default KycDetails